import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { find } from 'lodash-es';

import { tmapApi } from '../../libs/tmap-api';
import { AppsflyerInterfaceEvent, tmapInterface } from '../../libs/tmap-interface';
import { utils } from '../../libs/utils';
import { HeaderVoiceDetail } from '../../components/Layout';

import { RootState } from '../../store';
import { voiceManager } from '../../managers';
import { ProductItem } from '../../types/voice';
import { Price } from './components/Price';
import { RouteComponentProps } from 'react-router-dom';
import { tmapLogbox } from '../../libs/logbox';
import { globalVars } from '../../libs/global-vars';

function VoiceDetail(props: RouteComponentProps<{ id: string }>) {
  const [inited, setInited] = useState(false);
  const [itemData, setItemData] = useState<ProductItem>({} as ProductItem);

  const imageRef = useRef(null);
  const voiceState = useSelector((state: RootState) => state.voice);
  const purchaseList = useSelector((state: RootState) => state.voice.purchaseList);
  const purchaseInfo = useMemo(() => find(purchaseList, purchaseItem => purchaseItem.itemId === itemData.productId), [
    purchaseList,
    itemData.productId,
  ]);
  const isPlaying = useMemo(() => itemData.productId === voiceState.playingGuideType, [voiceState.playingGuideType, itemData]);
  const isFree = useMemo(() => itemData && itemData.sale && itemData.sale.price.sellingPrice === 0, [itemData]);
  const isCustomPurchaseInfo = useMemo(() => ((purchaseInfo && ['PLUS'].includes(purchaseInfo.purchaseType)) || false), [purchaseInfo]);
  const [openCaution, setOpenCaution] = useState(true);

  useEffect(() => {
    tmapApi.getProductDetail({ productId: props.match.params.id }).then(({ data }) => {
      delete data.header;
      setItemData(data);
      setInited(true);
      tmapInterface.recordEvent(AppsflyerInterfaceEvent.INTO_DETAIL_VOICE, { af_content: 'star_voice', af_content_id: data.productId });
    });
  }, [props.match.params.id]);
  
  // 판매 가능한 상품 체크 (판매 기간)
  const isPurchasableItem = useMemo(() => {
    if(itemData && itemData.sale && itemData.sale.period) {
      const current = parseInt(utils.dateToText(), 10)
      const start = parseInt(itemData.sale.period.startDateTime || '', 10)
      const end = parseInt(itemData.sale.period.endDateTime || '', 10)
      return (start <= current && current < end)
    }
  }, [itemData])
  
  const usagePeriod = useMemo(() => {
    let period = itemData.usagePeriod;

    // 이용 기간 계산
    if(purchaseInfo && isCustomPurchaseInfo) {
      const { usageStartDate, usageEndDate } = purchaseInfo.usagePeriod;
      const diffDay = utils.dateDiffToDay(usageStartDate, usageEndDate);

      if(diffDay) period = `${diffDay}`;
    }

    return period === '365' ? '1년' : `${period}일`;
  }, [itemData.usagePeriod, isCustomPurchaseInfo, purchaseInfo]);

  const purchaseTypeInfo = useMemo(() => {
    if(purchaseInfo && isCustomPurchaseInfo) {
      switch (purchaseInfo.purchaseType) {
        case 'PLUS':
          return '플러스 혜택 이용 중';
        default:
          return '';
      }
    }
  }, [isCustomPurchaseInfo, purchaseInfo])

  return (
    <>
      <HeaderVoiceDetail thumbnailUrl={itemData.cover && itemData.cover.thumbnailUrl} imageRef={imageRef} />
      {inited && itemData.productId && (
        <div className="content">
          <div className={`voice_detail ${purchaseInfo ? 'purchase_complete' : ''}`}>
            <section className="voice_detail_info">
              <div className="voice_detail_thumb">
                <button
                  type="button"
                  className={`thumb ${isPlaying ? 'tts' : ''}`}
                  onClick={() => voiceManager.playTTS(itemData)}
                  ref={imageRef}
                >
                  {itemData.cover.imageUrl ? <img src={itemData.cover.imageUrl} width="170" height="170" className="img" alt="" /> : null}
                  <span className="animate">
                    <img src={require('../../assets/images/play_detail.gif').default} className="ani" alt="" />
                  </span>
                </button>
                <h1 className="title">{itemData.name}</h1>
                <p className="desc">{itemData.description}</p>
              </div>
              <ul className="voice_detail_list">
                <li className="voice_detail_item">
                  <em className="label">이용금액</em>
                  <div className="cont">
                    <Price itemData={itemData} type="DETAIL" />
                    {
                      isCustomPurchaseInfo && purchaseTypeInfo && (
                          <p className='sale'>
                            <span className="rate">{purchaseTypeInfo}</span>
                          </p>
                      )
                    }
                  </div>
                </li>
                {purchaseInfo ? ( // 결제 후
                  <>
                    <li className="voice_detail_item">
                      <em className="label">이용기간</em>
                      <div className="cont">
                        <p className="text">{usagePeriod}</p>
                        <p className="text period">{utils.dateText(purchaseInfo.usagePeriod.usageEndDate)} 까지</p>
                      </div>
                    </li>
                    <li className="voice_detail_item">
                      <em className="label">결제 정보</em>
                      <div className="cont">
                        <p className="text number">주문번호 {purchaseInfo.purchaseItemId.slice(0, 13)}</p>
                        <p className="text datetime">{utils.dateText(purchaseInfo.usagePeriod.usageStartDate, 'time')}</p>
                      </div>
                    </li>
                  </>
                ) : (
                  // 결제 전
                  <>
                    <li className="voice_detail_item">
                      <em className="label">이용기간</em>
                      <div className="cont">
                        <p className="text">{usagePeriod}</p>
                      </div>
                    </li>
                  </>
                )}
              </ul>
            </section>

            <section className="voice_detail_notice">
              <h1
                className={`title ${openCaution ? 'unfold' : ''}`}
                onClick={() => {
                  const value = !openCaution;
                  setOpenCaution(value);
                  tmapLogbox.sendEvent(`tap.notice.${value ? 'open' : 'close'}`);
                }}
              >
                유의사항
              </h1>
              <p className="please">결제 전 꼭 확인하세요! 🚨</p>
              {openCaution && (
                <dl className="voice_detail_desc">
                  <dt className="tit">결제수단</dt>
                  <dd className="cont">
                    셀럽 결제는 {globalVars.isAndroid ? '플레이스토어' : '앱스토어'} 결제만 가능합니다.{' '}
                    {globalVars.isAndroid ? '플레이스토어' : '앱스토어'}에 등록된 결제수단이 기존에 없을 경우 등록과정이 필요합니다.
                  </dd>
                  {(!isCustomPurchaseInfo) && (
                    <>
                      <dt className="tit">이용기간</dt>
                      <dd className="cont">결제 완료일로부터 {usagePeriod}간 이용 가능합니다.</dd>
                    </>
                  )}
                  <dt className="tit">영수증 및 환불정책</dt>
                  <dd className="cont">
                    <p>
                      티맵에서는 결제 후 주문번호만 확인 가능하며 구매 영수증 및 환불정책 등 자세한 결제내역은{' '}
                      {globalVars.isAndroid ? '구글 플레이스토어 결제화면에서 확인하세요.' : '앱스토어 구입항목에서 확인하세요.'}
                    </p>
                    <a
                      href={globalVars.isAndroid ? 'https://pay.google.com' : 'https://support.apple.com/ko-kr/HT204084'}
                      target="_blank"
                      className="link"
                      rel="noreferrer"
                      onClick={e => {
                        e.preventDefault();
                        tmapInterface.openBrowser((e.target as HTMLAnchorElement).href);
                        tmapLogbox.sendEvent('tap.notice.store');
                      }}
                    >
                      {globalVars.isAndroid ? 'https://pay.google.com' : 'https://support.apple.com/ko-kr/HT204084'}
                    </a>
                  </dd>
                </dl>
              )}
            </section>

            <div className="voice_detail_footer">
              {(purchaseInfo || !isPurchasableItem) ? (
                <button type="button" className="btn_buy" disabled>
                  { purchaseInfo ? '구매 완료' : '판매 종료' }
                </button>
              ) : (
                <button
                  type="button"
                  className="btn_buy"
                  onClick={() => voiceManager.callPurchase(itemData)}
                  data-logbox-action="tap.purchase"
                  data-logbox-custom={JSON.stringify({ product_id: itemData.productId })}
                >
                  {isFree ? '체험하기' : '결제하기'}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export { VoiceDetail };
