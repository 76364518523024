import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { findIndex, isEmpty, union } from 'lodash-es';

import { selectPurchasedProductList } from '../../store/voice';
import { voiceManager } from '../../managers';
import { RootState } from "../../store";
import { CelebVoiceSelectionItem, DefaultVoiceList } from './components';
import { tmapInterface } from "../../libs/tmap-interface";

function VoiceListSelectionOnly() {
  const [inited, setInited] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const voiceState = useSelector((state: RootState) => state.voice);
  const purchasedProductList = useSelector(selectPurchasedProductList);


  useEffect(() => {
    // 배경색 투명하게
    const appDiv = document.getElementById('root');
    if(appDiv) appDiv.style.backgroundColor = 'transparent';

    tmapInterface.getUserSetting('local.useMapNightMode', (value) => {
      console.log('value', value);
      setIsDarkMode('true' === value);
    });
    voiceManager.resolveCacheDataForOnlySelection().then(() => setInited(true));
  }, []);

  // 구매한 보이스가 만료되어 사라졌을때 selectedGuideType 유지 되고 있을 경우 기본 보이스로 자동 변경
  useEffect(() => {
    if(inited) {
      const { selectedGuideType, defaultProductList } = voiceState;

      if (findIndex(union(defaultProductList, purchasedProductList), { productId: selectedGuideType }) < 0) {
        voiceManager.setGuideType(defaultProductList[0]);
      }
    }
  }, [inited, voiceState, voiceState.selectedGuideType, purchasedProductList]);

  return (
    <>
      {inited && (
        <div className={`container selection_only ${isDarkMode && 'dark'}`}>
          <div className="content">
            <section className="voice_type">
              {/* 구매한상품 */}
              {!isEmpty(purchasedProductList) && (
                <ul className="celeb_voice_list ">
                  {purchasedProductList!.map(itemData => (
                    <CelebVoiceSelectionItem itemData={itemData} key={itemData.productId} />
                  ))}
                </ul>
              )}
              {/* 기본보이스 */}
              <DefaultVoiceList />
            </section>

          </div>
        </div>
      )}
    </>
  );
}

export { VoiceListSelectionOnly };
