import qs from "qs";

import { modal } from '../components/GlobalModal';
import { eventBus } from '../libs/event-bus';
import { globalVars } from '../libs/global-vars';
import { tmapInterface } from '../libs/tmap-interface';
import { appActions, store } from '../store';
import { DisplayInfo } from '../types/app';
import compareVersions from 'compare-versions';

import { voiceManager } from './voice';

export interface voiceQueryString {
  promotionKey?: string,
  promotionValue?: string
}

const dispatch = store.dispatch;

const appManager = {
  initialize() {
    return new Promise(async resolve => {
      // resize
      eventBus.on('nativeCallback.onGetDisplayInfo', appManager.onGetDisplayInfo);
      window.addEventListener('resize', () => tmapInterface.getDisplayInfo());

      appManager.detectSchemeParams()

      await new Promise(resolve => {
        if (globalVars.isInApp) {
          tmapInterface.getDisplayInfo((dataStr: any) => {
            appManager.onGetDisplayInfo(JSON.parse(dataStr));
            resolve(true);
          });
        } else resolve(true);
      });

      await new Promise(resolve => {
        // get EUK
        if (globalVars.isInApp && compareVersions(globalVars.appVersion, '8.9.0') >= 0) {
          tmapInterface.getEUK(value => {
            globalVars.userEUK = value;
            resolve(true);
          });
        } else {
          resolve(true);
        }
      });

      await new Promise(resolve => {
        // get sessionId
        if (globalVars.isInApp && compareVersions(globalVars.appVersion, '9.9.0') >= 0) {
          tmapInterface.getSessionId(value => {
            globalVars.sessionId = value;
            resolve(true);
          });
        } else {
          resolve(true);
        }
      });

      await new Promise(resolve => {
        // get deviceId
        if (globalVars.isInApp && compareVersions(globalVars.appVersion, '9.7.0') >= 0) {
          tmapInterface.getDeviceId(value => {
            globalVars.deviceId = value;
            resolve(true);
          });
        } else {
          resolve(true);
        }
      });

      await new Promise(resolve => {
        // get carrierName
        if (globalVars.isInApp && compareVersions(globalVars.appVersion, '9.7.0') >= 0) {
          tmapInterface.getCarrierName(value => {
            globalVars.carrierName = value;
            resolve(true);
          });
        } else {
          resolve(true);
        }
      });

      voiceManager.initialize();
      resolve(true);
    });
  },

  detectSchemeParams: function () {
    const pageid = globalVars.queryData.pageid || '';
    const productid = globalVars.queryData.productid || '';

    if (globalVars.isRedApp && window.location.pathname !== '/voice/info') {
      window.history.replaceState(null, '', '/voice/info');

    } else if(pageid.startsWith('main-')) {
      const urlArr = pageid.split("_")[0].split('-');
      let queryString: string = '';

      if (urlArr.length > 1) {
        const param : voiceQueryString = {
          promotionKey: urlArr[1] || '',
          promotionValue: urlArr[2] || ''
        };

        queryString = qs.stringify(param);
        window.history.replaceState(null, '', `/voice/${param.promotionKey}${(queryString) && `?${queryString}`}`);
      }
    } else if (pageid.startsWith('celeb') && pageid) {
      window.history.replaceState(null, '', `/voice/celeb/${productid}`);
    } else if (pageid === 'driving') {
      window.history.replaceState(null, '', '/voice/driving');
    }
  },

  setSystemError(systemError: any) {
    const prevErrorState = store.getState().app.systemError;
    if (!prevErrorState) {
      dispatch(appActions.setSystemError(systemError));
      modal.alert(systemError.message, () => appManager.closeWebview());
    }
  },
  closeWebview() {
    tmapInterface.onBackKeyPressed();
  },
  setGlobalCover(bln: boolean) {
    dispatch(appActions.setGlobalCover(bln));
  },
  globalLoadingInc() {
    dispatch(appActions.globalLoadingInc());
  },
  globalLoadingDec() {
    dispatch(appActions.globalLoadingDec());
  },
  onGetDisplayInfo(displayInfo: DisplayInfo) {
    const body = document.querySelector('body');
    if (displayInfo.orientation === 2) body?.classList.add('landscape');
    else body?.classList.remove('landscape');
  },
};

export { appManager, voiceManager };
