import { tmapApi } from '../libs/tmap-api';
import { appActions, store, voiceActions } from '../store';
import { AppsflyerInterfaceEvent, tmapInterface, TmapInterfaceEvent } from '../libs/tmap-interface';
import { DefaultVoiceItem, ProductItem } from '../types/voice';
import { find, get, concat } from 'lodash-es';
import { eventBus } from '../libs/event-bus';
import { HistoryManagerEvent } from '../components/HistoryManager';
import { modal } from '../components/GlobalModal';
import { globalVars } from '../libs/global-vars';
import { tmapLogbox } from '../libs/logbox';
import { datadog } from '../libs/datadog-rum';
import { utils } from '../libs/utils'

interface ProductListParams {
  productType: string;
  onSale: boolean;
  includeTest?: boolean;
  couponNumber?: string;
}

const dispatch = store.dispatch;

const voiceManager = {
  /*
    초기화
  */
  initialize() {
    eventBus.on('nativeCallback.purchaseProductComplete', voiceManager.onPurchaseProductComplete);
    eventBus.on('nativeCallback.onStopTTS', () => dispatch(voiceActions.setPlayingGuideType('')));
    eventBus.on(HistoryManagerEvent.CHANGE_START, voiceManager.stopTTS);
    window.addEventListener('beforeunload', voiceManager.stopTTS);

    eventBus.on(TmapInterfaceEvent.CALL_INTERFACE, (method: string, params: any) => {
      datadog.addAction(`INTERFACE_CALL__${method}`, params);
    });
    eventBus.on(TmapInterfaceEvent.RECEIVED_CALLBACK, (method: string, params: any) => {
      datadog.addAction(`INTERFACE_CALLBACK__${method}`, params);
    });

    datadog.init();
  },

  /*
    api - store
  */
  async fetchBannerList(config?: any) {
    const { data } = await tmapApi.getBannerList({ adType: 'IN71' }, config);
    dispatch(voiceActions.setBannerList(data.advtBandBannerDetails));
  },
  getProductListParams(onSale: boolean): ProductListParams {
    const params: ProductListParams = { productType: 'VOICE', onSale };
    if (globalVars.testProductEnabled) params.includeTest = true;
    return params;
  },
  async fetchProductList(config?: any) {
    const { data } = await tmapApi.getProductList(voiceManager.getProductListParams(true), config);
    dispatch(voiceActions.setProductList(data.products || []));
    voiceManager.setCacheDataForOnlySelection();
  },
  async fetchExpiredProductList(config?: any) {
    const { data } = await tmapApi.getProductList(voiceManager.getProductListParams(false), config);
    dispatch(voiceActions.setExpiredProductList(data.products || []));
    voiceManager.setCacheDataForOnlySelection();
  },
  async fetchPurchaseList(config?: any) {
    const { data } = await tmapApi.getPurchaseList(
      {
        productType: 'VOICE',
        availableDate: 'NOW',
        purchaseState: 'NORMAL',
      },
      config,
    );
    dispatch(voiceActions.setPurchaseList(data.purchaseItems || []));
    voiceManager.setCacheDataForOnlySelection();
  },
  async fetchPaymentAgree(config?: any) {
    const { data } = await tmapApi.getPaymentServiceAgreementsChecked({ termsListType: 'PAID_SERVICE_01' }, config);
    dispatch(voiceActions.setPaymentAgree(data.allowYn === 'Y'));
  },
  async pushPaymentAgree(isAgree: boolean, config?: any) {
    await tmapApi.updatePaymentServiceAgreementsChecked({ termsListType: 'PAID_SERVICE_01', allowYn: isAgree ? 'Y' : 'N' }, config);
    dispatch(voiceActions.setPaymentAgree(isAgree));
  },
  async fetchSelectedGuideType() {
    // todo : getVoiceGuideType interface 동작 확인 필요
    // await new Promise((resolve, reject) => {
    //   tmapInterface.getVoiceGuideType(guideType => resolve(guideType));
    // });
  },
  setCacheDataForOnlySelection() {
    const voiceState = store.getState().voice;
    const cacheData = {
      ak: globalVars.accessKey,
      data: {
        saleProductList: voiceState.saleProductList,
        expiredProductList: voiceState.expiredProductList,
        purchaseList: voiceState.purchaseList,
      },
    };
    localStorage.setItem('_voiceCacheData', JSON.stringify(cacheData));
  },

  resolveCacheDataForOnlySelection() {
    return new Promise(resolve => {
      const cacheString = localStorage.getItem('_voiceCacheData');
      const cacheData = cacheString && JSON.parse(cacheString);
      const useCacheData =
        cacheData &&
        globalVars.accessKey === cacheData.ak &&
        cacheData.data.saleProductList &&
        cacheData.data.expiredProductList &&
        cacheData.data.purchaseList;
      if (useCacheData) {
        store.dispatch(voiceActions.setCacheDataForOnlySelection(cacheData.data));
        resolve(true);
      } else {
        Promise.all([voiceManager.fetchPurchaseList(), voiceManager.fetchProductList(), voiceManager.fetchExpiredProductList()]).then(
          () => {
            resolve(true);
          },
        );
      }
    });
  },

  /*
    functions
  */
  getProductData(productId: string): Nullable<ProductItem> {
    const voiceState = store.getState().voice;
    const fullList = concat(voiceState.saleProductList, voiceState.expiredProductList);
    return find(fullList, item => item?.productId === productId) || voiceState.purchasingItem;
  },
  setGuideType(productData: ProductItem | DefaultVoiceItem, callback?: Nullable<() => void>) {
    const { productId, name } = productData;
    const cdn = get(productData, 'feature.features.cdn', '');
    const thumbnailUrl = get(productData, 'cover.thumbnailUrl', '');
    dispatch(voiceActions.setSelectedGuideType(productId));
    localStorage.setItem('voiceGuideType', productId);
    tmapInterface.setVoiceGuideType(productId, name, cdn, thumbnailUrl);

    if(typeof callback === 'function') callback();
  },
  playTTS(productData: ProductItem, promotionId?: string) {
    /*
      재생중 stopTTS 호출하면 네이티브에서 stop하는데 delay가 발생 됨. (callback방식으로 제어된다고 함.)
      stop완료 후 play 요청하기 위해 delay 적용
    */
    const voiceState = store.getState().voice;
    const { productId, feature } = productData;
    const isPlaying = !!voiceState.playingGuideType;
    const isTargetPlaying = productId === voiceState.playingGuideType;
    if (isPlaying) {
      voiceManager.stopTTS();
      if (!isTargetPlaying) setTimeout(_callTTS, 100);
    } else {
      _callTTS();
    }
    function _callTTS() {
      const cdn = get(feature, 'features.cdn', '');
      const preview = get(feature, 'features.preview', '');
      dispatch(voiceActions.setPlayingGuideType(productId));
      tmapInterface.playTTS(productId, cdn, preview, null);
      if(promotionId) tmapLogbox.sendEvent('tap.listen', { product_id: productId, promotion_id: promotionId });
      else tmapLogbox.sendEvent('tap.listen', { product_id: productId });

    }
  },
  stopTTS() {
    const voiceState = store.getState().voice;
    const isPlaying = !!voiceState.playingGuideType;
    if (isPlaying) tmapInterface.stopTTS();
  },
  paymentAgreeCheck() {
    const voiceState = store.getState().voice;
    if (voiceState.paymentAgreeChecked) return Promise.resolve();
    return new Promise<void>((resolve, reject) => {
      modal.voiceServiceAgreement((result: boolean) => {
        if (result) voiceManager.pushPaymentAgree(true).then(() => resolve());
      });
    });
  },
  /*
    구매하기 버튼 클릭시 핸들러
    동의여부 체크, 구매한 아이템 갱신 후 유료/무료 구분 호출
  */
  async callPurchase(productData: ProductItem) {
    // 구매 중인 상품으로 store 에 저장
    dispatch(voiceActions.setPurchasingItem(productData));
    voiceManager.stopTTS();
    await Promise.all([voiceManager.paymentAgreeCheck(), voiceManager.fetchPurchaseList({ loading: false })]);
    const { productId } = productData;
    const isPaidItem = !!store.getState().voice.purchaseList?.find(item => item.itemId === productId);
    const price = get(productData, 'sale.price.sellingPrice');
    const cdn = get(productData, 'feature.features.cdn');
    if (!isPaidItem) {
      // 무료 인경우 직접 api 호출
      if (price === 0) this.purchaseFreeProductItem(productData);
      // 결제 인터페이스 호출
      else {
        dispatch(appActions.setGlobalCover(true));
        tmapInterface.purchaseProduct(productId, price, cdn);
      }
    }
  },
  /*
    무료상품 구매
  */
  async purchaseFreeProductItem(productData: ProductItem) {
    const purchaseItem = { itemId: productData.productId, purchaseToken: utils.generateToken('FREE') };
    const res = await tmapApi.registerPayment({ purchaseItem });

    const price = get(productData, 'sale.price.sellingPrice', 0);
    const eventParam = { af_revenue: `${price}`, af_content: 'star_voice', af_content_id: productData.productId, af_currency: 'KRW' };

    const { errorCode } = res.data.header;
    const actionId = `tap.purchase.${errorCode === '000000' ? 'success' : 'cancel'}`;
    tmapLogbox.sendEvent(actionId, { product_id: productData.productId });

    // 성공
    if(errorCode === '000000') {
      tmapInterface.recordEvent(AppsflyerInterfaceEvent.PURCHASE_SUCCESS, eventParam);
      dispatch(voiceActions.addPurchaseProductItem(res.data.purchaseItem));
      voiceManager.setGuideType(productData);
      voiceManager.alertPurchaseComplete();

    // 실패
    } else {
      tmapInterface.recordEvent(AppsflyerInterfaceEvent.PURCHASE_FAIL, eventParam);

      const message = '오류가 발생했습니다.<br/>고객센터에 문의해주세요.';
      modal.alert(message);
    }

  },
  /*
    쿠폰으로 구매
  */
  async purchaseByCoupon(productData: ProductItem, couponNumber: string, promotionId: string) {
    // 쿠폰 등록 버튼 클릭 이벤트
    const recordParam = { af_content: 'star_voice', af_content_id: productData.productId };
    tmapInterface.recordEvent(AppsflyerInterfaceEvent.COUPON_INSERT, recordParam);

    const purchaseItem = { itemId: productData.productId, purchaseToken: utils.generateToken('COUPON') };
    const res = await tmapApi.registerPayment({ purchaseItem, couponNumber, promotionId }, { skipErrorHandler: true });

    const { errorCode } = res.data.header;
    if (errorCode === '000000') {
      tmapInterface.recordEvent(AppsflyerInterfaceEvent.COUPON_INSERT_COMPLETE, recordParam);
      dispatch(voiceActions.addPurchaseProductItem(res.data.purchaseItem));
      voiceManager.setGuideType(productData);

      dispatch(voiceActions.setCouponProducts(null));
      eventBus.emit(HistoryManagerEvent.REPLACE_HISTORY, '/voice');
    } else {
      let message = `code=${errorCode}\n불편을 드려 죄송합니다.\n메뉴 > 고객센터 1:1문의로\n접수해주세요.`;
      tmapInterface.makeToast(message);
    }
  },
  /*
    쿠폰 유효성 검증 및 이용 가능한 상품 조회
  */
  async registerCoupon(couponNumber: string) {
    tmapLogbox.sendEvent('tap.coupon', { coupon: couponNumber });

    let param = voiceManager.getProductListParams(true);
    param.couponNumber = couponNumber;

    const { data } = await tmapApi.getProductList(param, { skipErrorHandler: true });
    const { errorCode, errorDetailCode } = data.header;

    const actionId = `tap.coupon.${errorCode === '000000' ? 'success' : 'fail'}`;
    tmapLogbox.sendEvent(actionId, { coupon: couponNumber });

    if(errorCode === '000000') dispatch(voiceActions.setCouponProducts(data));
    else {
      let message;
      switch (errorDetailCode) {
        case 'FAILRESULT_COUPON_NOTEXIST_ERROR': // 없는 쿠폰
          message = '존재하지 않는 쿠폰입니다.';
          break;
        case 'FAILRESULT_COUPON_ERROR': //쿠폰에 연결된 상품 조회 불가
          message = '쿠폰에 연결된 상품을 조회할 수 없습니다.';
          break;
        case 'COUPON_FORMAT_ERROR': //잘못된 쿠폰형식
          message = '올바르지 않은 쿠폰입니다.';
          break;
        case 'FAILRESULT_COUPON_ALREADYUSED_ERROR': //이미 사용한 쿠폰
        case 'COUPON_ALREADYUSED_ERROR':
          message = '이미 사용된 쿠폰입니다.';
          break;
        case 'COUPON_NOTISSUED_ERROR':
        case 'COUPON_PRODUCTMATCH_ERROR': //해당 상품에 사용불가한 쿠폰
          message = '이 상품에 사용할 수 없는 쿠폰입니다.'; // 기획서에 없음
          break;
        case 'FAILRESULT_COUPON_EXPIRED_ERROR': //쿠폰기간 만료
        case 'COUPON_EXPIRED_ERROR':
          message = '유효기간이 만료된 쿠폰입니다.'; // 기획서에 없음
          break;
        case 'COUPON_REGISTPROC_ERROR': //쿠폰 사용처리시 오류 발생
        default:
          message = '오류가 발생했습니다. <br/>고객센터에 문의해주세요.';
      }
      return message;
    }
  },
  /*
    유료상품 구매 (네이티브) 후 callback
  */
  onPurchaseProductComplete(isOK: boolean, productId: string, purchaseDataString: string) {
    const productData = voiceManager.getProductData(productId);
    productId = productData?.productId || ''; // 실패시에는 productId 가 담겨오고 있지 않음. 임시저장된 판매중인 상품값 사용되도록 재선언

    dispatch(appActions.setGlobalCover(false));
    tmapLogbox.sendEvent(`tap.purchase.${isOK ? 'success' : 'cancel'}`, { product_id: productId });

    const recordParam = { af_revenue: "0", af_content: 'star_voice', af_content_id: productId || '', af_currency: 'KRW' };
    if(productData) recordParam.af_revenue = `${get(productData, 'sale.price.sellingPrice', 0)}`;

    if (!isOK)  {
      tmapInterface.recordEvent(AppsflyerInterfaceEvent.PURCHASE_FAIL, recordParam);
      return;
    } else tmapInterface.recordEvent(AppsflyerInterfaceEvent.PURCHASE_SUCCESS, recordParam);

    const hasNativeData = !!purchaseDataString;
    if (productData) voiceManager.setGuideType(productData);
    if (hasNativeData) {
      const purchaseData = JSON.parse(purchaseDataString);
      dispatch(voiceActions.addPurchaseProductItem(purchaseData));
      voiceManager.alertPurchaseComplete(() => {
        voiceManager.moveToDetailPage(productId);
      });
    } else {
      voiceManager.alertPurchaseComplete(() => {
        voiceManager.fetchPurchaseList().then(() => voiceManager.moveToDetailPage(productId));
      });
    }
  },
  alertPurchaseComplete(callback?: Nullable<(...args: any[]) => any>) {
    modal.alert('성공적으로 구입이 완료되었습니다.', callback, '결제완료');
  },
  moveToDetailPage(productId: string) {
    const targetPath = `/voice/celeb/${productId}`;
    if (window.location.pathname !== targetPath) eventBus.emit(HistoryManagerEvent.PUSH_HISTORY, targetPath);
  },
  resetCouponProducts() {
    dispatch(voiceActions.setCouponProducts(null));
  },
};

export { voiceManager };
