import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { utils } from '../../../libs/utils';

import { ProductItem } from '../../../types/voice';
import { RootState } from '../../../store';
import { voiceManager } from '../../../managers';
import { tmapInterface } from "../../../libs/tmap-interface";

interface Props {
  itemData: ProductItem;
}

function CelebVoiceSelectionItem({ itemData }: Props) {
  const voiceState = useSelector((state: RootState) => state.voice);
  const usagePeriod = useMemo(() => {
    return utils.dateText(itemData.sale.period.endDateTime);
  }, [itemData]);

  const id = useMemo(() => `voice_${itemData.productId}`, [itemData]);
  const isCheckedItem = useMemo(() => itemData.productId === voiceState.selectedGuideType, [itemData, voiceState]);
  const logBoxCustom = useMemo(() => JSON.stringify({product_id: itemData.productId}), [itemData.productId]);

  return (
    <li className="celeb_voice_item">
      <div className={`celeb_voice_thumb`}>
        {itemData.cover.thumbnailUrl ? <img src={itemData.cover.thumbnailUrl} width="74" className="img" height="74" alt="" /> : null}
      </div>
      <div
        className="celeb_voice_info selected"
      >
        <div className="title">
          <p className={`name ${isCheckedItem ?  'checked' : ''}`}>{itemData.name}</p>
        </div>
        <p className="period">이용기간: {usagePeriod} 까지</p>
      </div>
      <div className="radio_area">
        <input
          type="radio"
          name="voice_type"
          className="blind"
          id={id}
          onChange={() => {
            voiceManager.setGuideType(itemData, () => {
              tmapInterface.onBackKeyPressed();
            });
          }}
          defaultChecked={isCheckedItem}
          data-logbox-action="tap.celeb"
          data-logbox-custom={logBoxCustom}
        />
        <label className="label" htmlFor={id}>
          <span className="blind">{itemData.name}</span>
        </label>
      </div>
    </li>
  );
}

export { CelebVoiceSelectionItem };
